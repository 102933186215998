import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
  <header className={classes.Toolbar}>
    <DrawerToggle 
    className={classes.Menu}
    clicked={props.drawerToggleClicked}></DrawerToggle>
    <p className={classes.pMobile}>
      Coded along <br/> with Udemy Course, <br/> by Gulyapas Poonkawinsiri</p>
    <div className={classes.Logo}>
      <Logo />
    </div>
    <p className={classes.pDesktop}>
      Coded along with Udemy Course, by Gulyapas Poonkawinsiri</p>
    <nav className={classes.DesktopOnly}>
      <NavigationItems isAuthenticated={props.isAuth}  />
    </nav>
  </header>
)

export default toolbar;